import { AbstractRepository } from '@/repositories/abstract-repository';

import {
    Guardian,
    GuardianCreateDto,
    GuardianUpdateDtoInterface
} from '@/families/models/guardian';
import { Contact } from '@/families/models/contact';

export class GuardiansRepository extends AbstractRepository<Guardian> {
    protected endpoint = ''; // Unused; here to fulfill implementation contract

    public async createOne(familyId: number, newGuardian: GuardianCreateDto): Promise<Guardian> {
        // We don't have a single createOne on the API, so pass in an a single item array.
        return (await this.client.post<Guardian>(`families/${familyId}/guardians`, [newGuardian])).data;
    }

    public async updateOne(familyId: number, guardian: GuardianUpdateDtoInterface, useSilentErrorHandler = false): Promise<Guardian> {
        if (useSilentErrorHandler) {
            return (await this.silentClient.put<Guardian>(`families/${familyId}/guardians/${guardian.id}`, guardian)).data;
        }
        return (await this.client.put<Guardian>(`families/${familyId}/guardians/${guardian.id}`, guardian)).data;
    }

    public async deleteOne(familyId: number, guardianId: number): Promise<void> {
        await this.client.delete<Contact>(`families/${familyId}/guardians/${guardianId}`);
    }
}
