import { setFamilyDefaultsForUpdate } from '@/families/families-utils';
import { CallFamilyDto } from '@/families/models/call-family-models';
import { AbstractRepository, ApiParameters } from '@/repositories/abstract-repository';
import {
    Family,
    FamilySearchParameters,
    FamilySearchResults,
    FamilyCreateDto,
    FamilyUpdateDtoInterface,
    BasicFamilySearchParameters,
    BasicFamilySearchResult, FamilyLastViewedInfo, RejectFamilyInterface, BulkDeleteFamiliesDto, FamiliesImportFileDto
} from '@/families/models/family';
import {
    LinkFamilyInterface,
    ReferFamilyInterface,
    TransferFamilyInterface
} from '@/families/models/linkReferTransfer';
import qs from 'qs';

export class FamiliesRepository extends AbstractRepository<Family> {
    readonly endpoint = 'families';

    // Search for families based on search parameters
    public async basicSearch(params: BasicFamilySearchParameters): Promise<Array<BasicFamilySearchResult>> {
        const results = await this.client.get<Array<BasicFamilySearchResult>>(
            this.endpoint + '/search',
            {
                params: params
            }
        );

        return results.data;
    }

    /**
     * Call a family.
     *
     * @param familyId
     * @param dto
     */
    public async callFamily(familyId: number, dto: CallFamilyDto): Promise<void> {
        await this.client.post(`${this.endpoint}/${familyId}/communications/dial`, dto);
    }

    public async search(params: FamilySearchParameters): Promise<FamilySearchResults> {
        const results = await this.client.get<FamilySearchResults>('search', {
            params: params
        });
        return results.data;
    }

    public async create(newFamily: FamilyCreateDto): Promise<Family> {
        newFamily = await setFamilyDefaultsForUpdate(newFamily) as FamilyCreateDto;
        const createResults = await this.client.post<Family>(this.endpoint, newFamily, {
            params: {
                allow_duplicates: '1'
            }
        });
        return createResults.data;
    }

    async deleteFamily(familyId: number) {
        await this.client.delete(`${this.endpoint}/${familyId}`);
    }

    public async update(family: FamilyUpdateDtoInterface, useSilentErrorHandler = false): Promise<Family> {
        family = await setFamilyDefaultsForUpdate(family) as FamilyUpdateDtoInterface;
        if (useSilentErrorHandler) {
            return (await this.silentClient.put<Family>(`${this.endpoint}/${family.id}`, family)).data;
        }
        return (await this.client.put<Family>(`${this.endpoint}/${family.id}`, family)).data;
    }

    public async getFamilyUpdatesfamily() {
        return (await this.client);
    }

    public async linkFamily(id: number, payload: LinkFamilyInterface): Promise<void> {
        await this.client.post(`${this.endpoint}/${id}/link`, payload);
    }

    public async transferFamily(id: number, payload: TransferFamilyInterface): Promise<void> {
        await this.client.patch(this.endpoint + '/' + id + '/transfer', payload);
    }

    public async referFamily(id: number, payload: ReferFamilyInterface): Promise<void> {
        await this.client.post(this.endpoint + '/' + id + '/refer', payload);
    }

    // Guess what? You can reject *any* family this way!
    public async rejectFamily(id: number, payload: RejectFamilyInterface): Promise<void> {
        await this.client.put(`${this.endpoint}/${id.toString()}/reject`, payload);
    }

    // get all pending opportunities for specified center
    public async getPending(id: number): Promise<Array<Family>> {
        const results = await this.client.get(this.endpoint + `/pending?org_id=${id}`);
        return results?.data ?? [];
    }

    public async getById(id: number): Promise<Family> {
        const results = await this.client.get(this.endpoint + `/${id}`);
        return results.data;
    }

    public async getByIdSilent(id: number): Promise<Family> {
        const results = await this.silentClient.get(this.endpoint + `/${id}`);
        return results.data;
    }

    public async getOne(id: number | string, params: ApiParameters = {}, quiet = false): Promise<Family> {
        let client = this.client;
        if (quiet) {
            client = this.silentClient;
        }
        const response = await client.get<Family>(this.endpoint + '/' + id, {
            params: params,
            paramsSerializer: params1 => {
                return qs.stringify(params1);
            }
        });
        return response.data;
    }

    public async getLastViewFamiliesByStaff(orgId: number | null = null): Promise<Array<FamilyLastViewedInfo>> {
        const params = orgId ? { org_id: orgId } : null;
        const response = await this.client.get<Array<FamilyLastViewedInfo>>(this.endpoint + '/recent', {
            params: params,
            paramsSerializer: params1 => {
                return qs.stringify(params1);
            }
        });
        return response.data;
    }

    public async getLastViewFamiliesCountByStaff(orgId: number | null = null): Promise<number> {
        const params = {
            count: true,
            org_id: orgId
        };

        const response = await this.client.get<any>(this.endpoint + '/recent', {
            params: params,
            paramsSerializer: params1 => {
                return qs.stringify(params1);
            }
        });
        return response.data.count as number;
    }

    public async bulkDeleteFamilies(familyFilterId: number, dto: BulkDeleteFamiliesDto): Promise<void> {
        await this.client.post('groups/' + familyFilterId + '/histories/delete-rejected', dto);
    }

    async uploadImport(importDto: FamiliesImportFileDto) {
        await this.client.post(this.endpoint + '/imports', importDto);
    }
}
