import {
    conversionSuccessIdentifier,
    conversionTimingIdentifier,
    ReportIdentifier,
    trendTrackingIdentifier
} from '@/reports/report-constants';
import ConversionTimingView from '@/reports/components/select-fields-modal/ConversionTimingView.vue';
import { Status } from '@/families/models/status';
import {
    ConversionTimingParameters,
    ConversionTimingPeriod,
    ConversionTimingPeriodType,
    ConversionTimingReportType,
    TrendTrackingParameters,
    TrendTrackingPeriodType,
    TrendTrackingReportType
} from '@/reports/standard/standard-special-reports';
import { addDays, addYears, formatDateForApi } from '@/date-time/date-time-utils';
import qs from 'qs';
import { baseUrl } from '@/core/base-url';
import { getDefaultReportData } from '@/reports/report-utils';
import Flexmonster, { Report, SliceHierarchy, SliceMeasure } from 'flexmonster';
import TrendTrackingView from '@/reports/components/select-fields-modal/TrendTrackingView.vue';
import { ReportMappingDisplayInterface } from '@/reports/models/reports';

export class StandardReportUtils {
    static addConversionTimingPeriod(periods: Array<ConversionTimingPeriod>) {
        const last = periods[periods.length - 1];
        if (!last) {
            periods.push({
                type: ConversionTimingPeriodType.DAY,
                from: 0,
                to: 7
            });
            return;
        }
        periods.push({
            type: last.type,
            from: Number(last.to) + 1,
            to: Number(last.to) + 1 + Math.max(0, (Number(last.to) - Number(last.from)))
        });
    }

    static deriveStandardReport(report: Report) {
        if (!report.dataSource || !report.dataSource.filename) {
            return null;
        }
        if (report.dataSource.filename.match(/conversion-timing/)) {
            return conversionTimingIdentifier;
        }
        if (report.dataSource.filename.match(/conversion-success/)) {
            if (this.convSucessHeuristic(report)) {
                return conversionSuccessIdentifier;
            }
        }
        if (report.dataSource.filename.match(/marketing-campaigns/)) {
            return ReportIdentifier.MARKETING_CAMPAIGN_ANALYSIS;
        }
        if (report.dataSource.filename.match(/trend-tracking/)) {
            return trendTrackingIdentifier;
        }
        return null;
    }

    static getConversionTimingReport(reportParams: ConversionTimingParameters, currentCustomerDb: number | null, offsetString: string, dateFormat: string, token: string) {
        let baseApiUrl = baseUrl + '/api/v3';
        if (currentCustomerDb) {
            baseApiUrl = `${baseUrl}/api/admin/v1/customers/${currentCustomerDb}`;
        }
        let reportUrl = baseApiUrl + '/reports/conversion-timing';
        reportUrl += '?' + qs.stringify(reportParams);
        const mappingUrl = reportUrl + '&getMapping=1';
        const reportData = getDefaultReportData(offsetString, dateFormat, reportUrl, mappingUrl, 'Conversion Timing', token);
        if (reportData.options) {
            reportData.options.showDefaultSlice = true;
        }
        if (reportParams.report_type === ConversionTimingReportType.PERIOD_PCT) {
            const measures: Array<SliceMeasure> = [
                {
                    uniqueName: 'location_name'
                },
                {
                    uniqueName: 'total_count'
                }
            ];
            for (let i = 0; i < reportParams.periods.length; i++) {
                measures.push({
                    uniqueName: 'period_' + i,
                    aggregation: 'sum',
                    format: 'percentage'
                });
            }
            reportData.slice = {
                measures
            };
        }
        return reportData;
    }

    static getTrendTrackingReport(reportParams: TrendTrackingParameters, selectedFields: Array<string>, currentCustomerDb: number | null, offsetString: string, dateFormat: string, token: string) {
        let baseApiUrl = baseUrl + '/api/v3';
        if (currentCustomerDb) {
            baseApiUrl = `${baseUrl}/api/admin/v1/customers/${currentCustomerDb}`;
        }
        let reportUrl = baseApiUrl + '/reports/trend-tracking';
        reportUrl += '?' + qs.stringify(reportParams);
        const mappingUrl = reportUrl + '&getMapping=1';
        const reportData = getDefaultReportData(offsetString, dateFormat, reportUrl, mappingUrl, 'Trend Tracking', token);
        if (reportData.options) {
            if (reportData.options.grid) {
                reportData.options.grid.showGrandTotals = 'columns';
                reportData.options.grid.type = 'classic';
            }
        }
        const rowSlices: Array<SliceHierarchy> = [];
        for (const field of selectedFields) {
            rowSlices.push({
                uniqueName: field
            });
        }
        let lastRow = '';
        switch (reportParams.report_type) {
            case TrendTrackingReportType.STATUS:
                lastRow = 'status';
                break;
            case TrendTrackingReportType.INQUIRY:
                lastRow = 'inquiry_type';
                break;
            case TrendTrackingReportType.SOURCE:
                lastRow = 'source';
                break;
            case TrendTrackingReportType.MARKETING:
                lastRow = 'marketing_campaign';
                break;
        }
        rowSlices.push({
            uniqueName: lastRow
        });
        reportData.slice = {
            rows: rowSlices,
            columns: [
                {
                    uniqueName: '[Measures]'
                },
                {
                    uniqueName: 'period'
                }
            ],
            measures: [
                {
                    uniqueName: 'datetime',
                    aggregation: 'count'
                }
            ],
            expands: {
                expandAll: true
            }
        };
        return reportData;
    }

    static getDefaultConversionTimingParams(statuses: Array<Status>, savedReport: Report | null = null): ConversionTimingParameters {
        const firstActive = statuses.filter(status => !status.is_archive)[0];
        const firstArchive = statuses.filter(status => status.is_archive)[0];
        const today = formatDateForApi(new Date(Date.now()));
        const lastJan1st = addYears(today, -1).substring(0, 5) + '01-01';
        const params = {
            from_status: firstActive.id,
            to_status: firstArchive.id,
            by_families: false,
            start_date: lastJan1st,
            end_date: today,
            report_type: ConversionTimingReportType.AVG,
            periods: [
                { type: ConversionTimingPeriodType.DAY, from: 0, to: 7 },
                { type: ConversionTimingPeriodType.DAY, from: 8, to: 14 }
            ]
        };
        if (!savedReport || !savedReport.dataSource || !savedReport.dataSource.filename) {
            return params;
        }
        const url = new URL(savedReport.dataSource.filename);
        const urlParams = url.searchParams;
        if (urlParams.get('by_families') === 'true') {
            params.by_families = true;
        }
        params.start_date = urlParams.get('start_date') ?? params.start_date;
        params.end_date = urlParams.get('end_date') ?? params.end_date;
        switch (Number(urlParams.get('report_type'))) {
            case ConversionTimingReportType.PERIOD_COUNT:
                params.report_type = ConversionTimingReportType.PERIOD_COUNT;
                break;
            case ConversionTimingReportType.PERIOD_PCT:
                params.report_type = ConversionTimingReportType.PERIOD_PCT;
                break;
        }
        if (urlParams.get('from_status')) {
            params.from_status = Number(urlParams.get('from_status')) ?? params.from_status;
        }
        if (urlParams.get('to_status')) {
            params.to_status = Number(urlParams.get('to_status')) ?? params.to_status;
        }
        const parsed = qs.parse(url.search);
        if (parsed.periods && Array.isArray(parsed.periods)) {
            params.periods = [];
            const periods = parsed.periods as unknown as Array<ConversionTimingPeriod>;
            for (const period of periods) {
                params.periods.push({
                    type: Number(period.type),
                    from: Number(period.from),
                    to: Number(period.to)
                });
            }
        }
        return params;
    }

    /**
     * Get the sorted rows for the standard report as defined in the standard report object.
     * The order defined in report.slice.rows should be followed, with all the other selected rows appended to the end.
     *
     * @param report
     * @param selectedRows
     */
    static getSortedRows(report: Report, selectedRows: Array<SliceHierarchy>): Array<SliceHierarchy> {
        if (!report.slice || !report.slice.rows) {
            return selectedRows;
        }

        const map: Map<number, SliceHierarchy> = new Map();
        const defaultRowUniqueNames = report.slice.rows.map(row => row.uniqueName);
        let minUnknownIndex = 100;
        for (const selectedField of selectedRows) {
            const index = defaultRowUniqueNames?.indexOf(selectedField.uniqueName);
            if (index !== -1) {
                map.set(index, selectedField);
            } else {
                map.set(minUnknownIndex++, selectedField);
            }
        }

        return Array.from((new Map([...map].sort((a, b) => a[0] - b[0]))).values());
    }

    static getDefaultTrendTrackingParams(savedReport: Flexmonster.Report | null = null) {
        const today = formatDateForApi(new Date(Date.now()));
        const params: TrendTrackingParameters = {
            start_date: formatDateForApi(addDays(today, -15)),
            count_children: true,
            report_type: TrendTrackingReportType.STATUS,
            period_type: TrendTrackingPeriodType.WEEK,
            period_count: 2
        };
        if (!savedReport || !savedReport.dataSource || !savedReport.dataSource.filename) {
            return params;
        }
        const url = new URL(savedReport.dataSource.filename);
        const urlParams = url.searchParams;
        if (urlParams.get('count_children') === 'false') {
            params.count_children = false;
        }
        params.start_date = urlParams.get('start_date') ?? params.start_date;
        params.period_count = urlParams.get('period_count') ? Number(urlParams.get('period_count')) : params.period_count;
        params.period_type = urlParams.get('period_type') ? Number(urlParams.get('period_type')) : params.period_type;
        params.report_type = urlParams.get('report_type') ? Number(urlParams.get('report_type')) : params.report_type;
        return params;
    }

    static hasFields(standardReportIdentifier: string | null) {
        return !StandardReportUtils.isConversionTiming(standardReportIdentifier);
    }

    static hasNormalViews(standardReportIdentifier: string | null) {
        return !StandardReportUtils.isConversionTiming(standardReportIdentifier) && !StandardReportUtils.isTrendTracking(standardReportIdentifier);
    }

    static isConversionTiming(standardReportIdentifier: string | null): boolean {
        return standardReportIdentifier === conversionTimingIdentifier;
    }

    static isTrendTracking(standardReportIdentifier: string | null): boolean {
        return standardReportIdentifier === trendTrackingIdentifier;
    }

    static shouldShowDateRange(reportIdentifier: string) {
        return ![
            conversionTimingIdentifier.toString(),
            trendTrackingIdentifier.toString(),
            ReportIdentifier.MARKETING_CAMPAIGN_ANALYSIS
        ].includes(reportIdentifier);
    }

    static specialViewComponent(standardReportIdentifier: string | null) {
        if (StandardReportUtils.isConversionTiming(standardReportIdentifier)) {
            return ConversionTimingView;
        }
        if (StandardReportUtils.isTrendTracking(standardReportIdentifier)) {
            return TrendTrackingView;
        }
        return null;
    }

    static postLoadFormatting(standard_report_identifier: string, flexmonster: Flexmonster.Pivot) {
        if (standard_report_identifier === conversionTimingIdentifier) {
            const rowsCount = (flexmonster as any).gridRowCount();
            flexmonster.customizeCell((cell, data) => {
                if (data.rowIndex === rowsCount - 1) {
                    cell.addClass('flex-totals-row');
                }
            });
        } else {
            flexmonster.customizeCell(null!);
        }
    }

    static trendTrackingMapFilter(mapping: ReportMappingDisplayInterface, trendTrackingParams: TrendTrackingParameters | null) {
        if (!trendTrackingParams) {
            return true;
        }
        const alwaysFilter = ['status', 'inquiry_type', 'source', 'marketing_campaign', 'lead_id', 'datetime', 'period'];
        if (alwaysFilter.includes(mapping.uniqueName)) {
            return false;
        }
        if (!trendTrackingParams.count_children && (
            mapping.uniqueName.match(/child/) || mapping.uniqueName === 'classroom'
        )) {
            return false;
        }
        if (mapping.uniqueName.match(/^type_/)) {
            return false;
        }
        return true;
    }

    /**
     * We don't want to mess with filters on saved reports that are likely not based on a standard conv success report.
     * @param report
     * @private
     */
    private static convSucessHeuristic(report: Flexmonster.Report) {
        const filters = report.slice?.reportFilters;
        if (!filters?.length || filters.length < 7) {
            return false;
        }
        for (const filter of filters) {
            if (!filter.uniqueName.match(/_date$/)) {
                return false;
            }
        }
        return true;
    }
}
