import { ConversionSuccessReportPayload, StandardReportType } from '@/reports/models/saved-report';
import { ReportIdentifier, StandardReportIdentifier } from '@/reports/report-constants';
import { Slice, SliceHierarchy, SliceMeasure, Format } from 'flexmonster';
import { getModule } from 'vuex-module-decorators';
import { StatusesStore } from '@/families/store/statuses-store';
import { Status } from '@/families/models/status';
import { BaseStatuses } from '@/constants/status-constants';
import { ReportObjectUtils } from '@/reports/report-object-utils';
import { formatDateForApi } from '@/date-time/date-time-utils';

const statusesStore = getModule(StatusesStore);
const reportObjectUtils = new ReportObjectUtils();

export class ConversionSuccessReportUtils {

    /**
     * Build set of 'status_${id}' to accommodate uniqueName
     * @param statuses
     */
    public buildSetForStatus(statuses: Array<Status>): Set<string> {
        const statusSet: Set<string> = new Set<string>();
        statuses.forEach(status => {
            const keyDateString = `status_${status.id.toString()}`;
            if (!statusSet.has(keyDateString)) {
                statusSet.add(keyDateString);
            }
        });
        return statusSet;
    }

    /**
     * Build set of 'status_${id}_date' to accommodate uniqueName
     * @param statuses
     */
    public buildSetForStatusDate(statuses: Array<Status>): Set<string> {
        const statusDateSet: Set<string> = new Set<string>();
        statuses.forEach(status => {
            const keyDateString = `status_${status.id.toString()}_date`;
            if (!statusDateSet.has(keyDateString)) {
                statusDateSet.add(keyDateString);
            }
        });
        return statusDateSet;
    }

    /**
     * Build status array to popular measure name dynamically when percentages is involved
     * @param statuses
     */
    public buildStatusesArray(selectedStatus: Status | null, statuses: Array<Status>): Array<Status> {
        if (selectedStatus) {
            const statusIndex = statuses.findIndex(s => s.id === selectedStatus.id);
            if (statusIndex > 0) {
                statuses.splice(0, statusIndex);
            }
        }
        return statuses;
    }

    /**
     * Convert correct dynamic slice for Conversion Success Report
     * @param payload
     */
    public async convertSliceForConversionSuccessReport(payload: ConversionSuccessReportPayload): Promise<Slice | null> {
        await statusesStore.init();
        // We show this combination of statuses in conversion success report
        let statuses = statusesStore.statuses.filter(status => !status.is_archive || status.id === BaseStatuses.ENROLLED || status.id === BaseStatuses.LOST_OPP);
        if (this.hasPercentageCalculations(payload.selectedView)) {
            statuses = this.buildStatusesArray(payload.selectedStatus, statuses);
        }
        const statusDateSet: Set<string> = this.buildSetForStatusDate(statuses);
        // Pull correct hard-coded slice
        const resultSlice = reportObjectUtils.retrieveSliceForStandardReport(payload.selectedView, payload.standardReportType);
        const selectedFields = payload.selectedFields;
        if (resultSlice) {
            if (resultSlice.measures) {
                resultSlice.rows = reportObjectUtils.buildSliceFromArrayOfString(selectedFields).rows;
                resultSlice.flatOrder = reportObjectUtils.buildSliceFromArrayOfString(selectedFields).flatOrder;
                resultSlice.reportFilters = this.generateReportFilters(selectedFields, payload, statusDateSet, this.hasPercentageCalculations(payload.selectedView));
                if (resultSlice.measures) {
                    resultSlice.measures = this.generateReportMeasures(payload, resultSlice.measures, statuses);
                }
            }
            return resultSlice;
        }

        return null;
    }

    /**
     * Generate formats for report data object
     * @param statues
     */
    public generateFormat(statuses: Array<Status>): Array<Format> {
        const formats: Array<Format> = [];
        formats.push({
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            decimalPlaces: 0,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            divideByZeroValue: 'Infinity',
            thousandsSeparator: ' ',
            positiveCurrencyFormat: '$1'
        });
        statuses.forEach(status => {
            formats.push({
                name: `status_${status.id.toString()}`,
                isPercent: true,
                decimalPlaces: 2
            });
        });
        return formats;
    }

    /**
     * Fix filters in the Slice Object
     * @param filters
     */
    public fixFilters(filters: Array<SliceHierarchy>) {
        for (const filter of filters) {
            if (filter.filter && !filter.filter.include) {
                filter.filter.include = [''];
            }
        }
    }

    /**
     * Generate reportFilters in the Slice Object for status date, child/guardian added date
     * @param selectedFields
     * @param payload
     * @param statusDateSet
     * @param hasPercentages
     */
    public generateReportFilters(selectedFields: Array<string>, payload: ConversionSuccessReportPayload, statusDateSet: Set<string>, hasPercentages = false): Array<SliceHierarchy> {
        const selectedStatus = payload.selectedStatus;
        let statusDateKey = '';
        let isDerivedStatus = false;
        const statusMatchResult = (payload.reportParams?.date_field ?? '').match(/^status_(\d+)_date$/);
        if (selectedStatus) {
            statusDateKey = `status_${selectedStatus.id.toString()}_date`;
        } else if (statusMatchResult) {
            isDerivedStatus = true;
            statusDateKey = statusMatchResult[1];
        }

        const today = new Date();
        const firstDayOfLastYear = new Date((new Date().getFullYear() - 1), 0, 1);
        const reportFilters: Array<SliceHierarchy> = [];
        selectedFields.forEach(field => {
            if (field === 'child_added_date' && (!statusDateKey || !isDerivedStatus)) {
                // If pre-filter date report is enabled, use the date range from the date range filter components
                if (payload.isDateFilterable && payload.reportParams.start_date && payload.reportParams.end_date) {
                    reportFilters.push({
                        uniqueName: field,
                        filter: {
                            query: {
                                between: [payload.reportParams.start_date, payload.reportParams.end_date]
                            },
                            include: ['']
                        }
                    });
                } else {
                    // If pre-filter date report is disabled, use the date range from January 1st of last year to Today
                    reportFilters.push({
                        uniqueName: field,
                        filter: {
                            query: {
                                between: [formatDateForApi(firstDayOfLastYear), formatDateForApi(today)]
                            },
                            include: ['']
                        }
                    });
                }
            }
            // If any type of status date field is selected
            if (statusDateSet.has(field)) {
                // If percentage is involved, then the status date field is selected is the same status as the "Converted From Status" pick list
                if (hasPercentages && field === statusDateKey) {
                    // Have the same logic as child_added_date field filter (same date range)
                    if (payload.isDateFilterable && payload.reportParams.start_date && payload.reportParams.end_date) {
                        reportFilters.push({
                            uniqueName: field,
                            filter: {
                                query: {
                                    between: [payload.reportParams.start_date, payload.reportParams.end_date]
                                },
                                include: ['']
                            }
                        });
                    } else {
                        reportFilters.push({
                            uniqueName: field,
                            filter: {
                                query: {
                                    between: [formatDateForApi(firstDayOfLastYear), formatDateForApi(today)]
                                },
                                include: ['']
                            }
                        });
                    }
                } else {
                    // Otherwise default values to: before or equals [Converted By Date]
                    reportFilters.push({
                        uniqueName: field,
                        filter: {
                            query: {
                                before_equal: payload.convertedStatusDate
                            },
                            include: ['']
                        }
                    });
                }
            }
        });
        return reportFilters;
    }

    /**
     * Generate measures in the Slice Object by omitting correct status columns order, populate correct calulations and populate dynamic status name, apply for percentages calulation
     * @param payload
     * @param measures
     * @param statuses
     * @param onlyPercentages
     */
    public generateReportMeasures(payload: ConversionSuccessReportPayload, measures: Array<SliceMeasure>, statuses: Array<Status>): Array<SliceHierarchy> {
        const selectedStatus = payload.selectedStatus;
        let statusKey = '';
        const resultMeasures: Array<SliceMeasure> = [];

        switch (payload.selectedView) {
            case StandardReportIdentifier.CONVERSION_SUCCESS_NUMBERS:
                statuses.forEach(status => {
                    resultMeasures.push({
                        uniqueName: `status_${status.id.toString()}`,
                        aggregation: 'count'
                    });
                });
                break;
            case StandardReportIdentifier.CONVERSION_SUCCESS_PERCENTAGES:
                if (selectedStatus) {
                    statusKey = `status_${selectedStatus.id.toString()}`;
                    statuses.forEach((status, index) => {
                        if (index === 0) {
                            resultMeasures.push({
                                uniqueName: statusKey,
                                aggregation: 'count'
                            });
                        } else {
                            resultMeasures.push({
                                format: `status_${status.id.toString()}`,
                                caption: `${status.name} (%)`,
                                formula: `count("status_${status.id.toString()}") / count("${statusKey}")`,
                                uniqueName: `${status.name} (%)`
                            });
                        }
                    });
                }
                break;
            case StandardReportIdentifier.CONVERSION_SUCCESS_BOTH:
                if (selectedStatus) {
                    statusKey = `status_${selectedStatus.id.toString()}`;
                    statuses.forEach((status, index) => {
                        if (index === 0) {
                            resultMeasures.push({
                                uniqueName: statusKey,
                                aggregation: 'count'
                            });
                        } else {
                            resultMeasures.push(
                                {
                                    uniqueName: `status_${status.id.toString()}`,
                                    aggregation: 'count'
                                },
                                {
                                    format: `status_${status.id.toString()}`,
                                    caption: `${status.name} (%)`,
                                    formula: `count("status_${status.id.toString()}") / count("${statusKey}")`,
                                    uniqueName: `${status.name} (%)`
                                });
                        }
                    });
                }
                break;
        }
        return resultMeasures;
    }

    /**
     * Check if conversion success report has percentages calculations
     * @param selectedStandardReportType
     */
    public hasPercentageCalculations(selectedStandardReportType: string | StandardReportIdentifier | null): boolean {
        if (selectedStandardReportType) {
            return selectedStandardReportType === StandardReportIdentifier.CONVERSION_SUCCESS_PERCENTAGES ||
                selectedStandardReportType === StandardReportIdentifier.CONVERSION_SUCCESS_BOTH;
        }
        return false;
    }

    /**
     * Check if report is a conversion success report
     * @param standardReportType
     */
    public isConversionSuccessReport(standardReportType: StandardReportType | null): boolean {
        if (standardReportType) {
            return standardReportType.identifier === ReportIdentifier.CONVERSION_SUCCESS;
        }
        return false;
    }

}
