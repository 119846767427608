import { baseUrl } from '@/core/base-url';
import axios from 'axios';

interface AnonInfo {
    country: string;
    legal_name: string;
    location_phone: string;
    location_email: string;
}

export class AnonInfoService {
    /**
     * @param formId
     * @param centerId
     */
    public async getAnonInfo(formId?: string, centerId?: string): Promise<AnonInfo> {
        const client = axios.create({ baseURL: baseUrl });
        const response = await client.get<AnonInfo>(`/api/anon-info?formId=${formId}&cid=${centerId}`);
        return response.data;
    }
}
