/**
 * Class for handling LLM-related utilities.
 */
export class LlmUtils {
    private baseUrl = '';

    public constructor() {
        this.baseUrl = process.env.VUE_APP_MANAGE_URL ?? '';
    }

    /**
     * Get the URL for a person.
     *
     * @param personId
     */
    getPersonUrl(personId: string): string {
        return `${this.baseUrl}/people/${personId}`;
    }
}
